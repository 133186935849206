// import AsyncStorage from '@react-native-async-storage/async-storage';
import createDataContext from './createDataContext';
import senseiApi from '../api/sensei';
// import { navigate } from '../navigationRef';

const sensorReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'get_devices':
      var tempDevices = action.payload.devices;
      if (state.devices === null || tempDevices.length > state.devices.length) {
        // console.log("Sorting by lastupdate.");
        tempDevices.sort((a: any, b: any) => (new Date(a.lastupdate) < new Date(b.lastupdate)) ? 1 : -1);
        tempDevices.forEach((device: any, index: number) => {
          tempDevices[index].weight = index;
          let mostRecent = new Date(tempDevices[index].lastupdate);
          mostRecent.setSeconds(mostRecent.getSeconds() + 300);
          let now = new Date();
          if (mostRecent > now) {
            tempDevices[index].online = true;
          } else {
            tempDevices[index].online = false;
          }
        });
      } else {
        // console.log("Sorting by weight.");
        tempDevices.forEach((device: any, index: number) => {
          state.devices.forEach((device2: any, index2: number) => {
            if (device.did === device2.did) {
              device.weight = device2.weight;
            }
          });
          let mostRecent = new Date(tempDevices[index].lastupdate);
          mostRecent.setSeconds(mostRecent.getSeconds() + 300);
          let now = new Date();
          if (mostRecent > now) {
            tempDevices[index].online = true;
          } else {
            tempDevices[index].online = false;
          }
        });
        tempDevices.sort((a: any, b: any) => (a.weight > b.weight) ? 1 : -1);
      }
      return { ...state, devices: tempDevices, authError: false };
    case 'set-current-device':
      return { ...state, currentDevice: action.payload};
    case 'set-averages-interval':
      return { ...state, averagesInterval: action.payload};
    case 'set-start-date-time':
      return { ...state, startDateTime: action.payload};
    case 'set-end-date-time':
      return { ...state, endDateTime: action.payload};
    case 'auth-error':
      return { ...state, authError: true };
    case 'clear-error':
      return { ...state, authError: false };
    case 'toggle-settings':
      return { ...state, settingsOpen: action.payload?.toggle !== undefined ? action.payload.toggle : !(state.settingsOpen) };
    default:
      return state;
  }
};

const fetchDevices = (dispatch: any) => async () => {
  try {
    const response = await senseiApi.get('/get-devices');
    dispatch({ type: 'get_devices', payload: response.data });
  } catch (error) {
    // console.log(error);
  }
  // console.log("Found devices:");
  // console.log(response.data);
};

const fetchMyDevices = (dispatch: any) => async () => {
  try {
    // console.log("fetchMyDevices function: ");
    const response = await senseiApi.get('/get-my-devices', { withCredentials: true });
    // console.log("fetchMyDevices response: ", response);
    dispatch({ type: 'get_devices', payload: response.data });
  } catch (error: any) {
    // console.log("fetchMyDevices error: ", error);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth-error' });
      }
    }
  }
  // console.log("Found devices:");
  // console.log(response.data);
};

const setCurrentDevice = (dispatch: any) => (did: number) => {
  try {
    dispatch({ type: 'set-current-device', payload: did})
  } catch (error) {
    // console.log(error);
  }
}

const clearError = (dispatch: any) => () => {
  dispatch({ type: 'clear-error' });
};

const toggleSettings = (dispatch: any) => (toggle?: boolean | undefined) => {
  dispatch({ type: 'toggle-settings', payload: toggle });
};

const setAveragesInterval = (dispatch: any) => (interval: string | undefined) => {
  dispatch({ type: 'set-averages-interval', payload: interval });
}

const setStartDateTime = (dispatch: any) => (start: string | undefined) => {
  dispatch({ type: 'set-start-date-time', payload: start });
}

const setEndDateTime = (dispatch: any) => (end: string | undefined) => {
  dispatch({ type: 'set-end-date-time', payload: end });
}

// const createTrack = (dispatch: any) => async (name: any, locations: any) => {
//     await senseiApi.post('/tracks', { name, locations });
//     console.log(name, locations.length);
// };

export const { Provider, Context } = createDataContext(
  sensorReducer,
  { fetchDevices, fetchMyDevices, setCurrentDevice, setAveragesInterval, setStartDateTime, setEndDateTime, clearError, toggleSettings },
  { devices: null, currentDevice: -1, averagesInterval: 'mins', startDateTime: 'now', endDateTime: 'now', authError: false, settingsOpen: false }
);