import React from 'react'

import { ImSpinner2 } from 'react-icons/im'

import styles from './loadingSpinner.module.scss'


const LoadingSpinner = () => {
  return (
    <> 
      <ImSpinner2 className={styles.spinner}/>
    </>
  )
}

export default LoadingSpinner