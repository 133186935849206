import createDataContext from './createDataContext';
// import senseiApi from '../api/sensei';
// import { navigate } from '../navigationRef';
import Cookies from 'universal-cookie';
import i18n from "i18next";

const cookies = new Cookies();

const settingsReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'hide_offline':
            return { ...state, hideOffline: action.payload };
        case 'set_temp_scale':
            return { ...state, tempScale: action.payload };
        case 'set_language':
            return { ...state, language: action.payload };
        case 'refresh_settings':
            // console.log(action.payload.hide_offline);
            return {hideOffline: action.payload.hide_offline, tempScale: action.payload.set_temp_scale, language: action.payload.set_language};
        default:
            return state;
    }
};

const displayOffline = (dispatch: any) => (displayBool: Boolean) => {
    let expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);
    cookies.set('hide_offline', displayBool, {sameSite: 'strict', expires: expireDate});
    dispatch({type:'hide_offline', payload: displayBool});
};

const setTempScale = (dispatch: any) => (scale: string) => {
    let expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);
    cookies.set('set_temp_scale', scale, {sameSite: 'strict', expires: expireDate});
    dispatch({type:'set_temp_scale', payload: scale});
};

const setLanguage = (dispatch: any) => (language: string) => {
    let expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);
    cookies.set('set_language', language, {sameSite: 'strict', expires: expireDate});
    i18n.changeLanguage(language);
    dispatch({type:'set_language', payload: language});
};

const refreshSettings = (dispatch: any) => () => {
    let tempScaleCookie = (cookies.get('set_temp_scale')) ? cookies.get('set_temp_scale') : 'F';
    let hideOfflineCookie = (typeof cookies.get('hide_offline') !== 'undefined') ? cookies.get('hide_offline') === 'true' : true;
    let languageCookie = (cookies.get('set_language')) ? cookies.get('set_language') : 'en';
    // console.log(cookies.get('set_language'));
    // console.log(languageCookie);
    i18n.changeLanguage(languageCookie);
    dispatch({type:'refresh_settings', payload: {
        hide_offline: hideOfflineCookie, 
        set_temp_scale: tempScaleCookie, 
        set_language: languageCookie
    }});
};


export const { Provider, Context } = createDataContext(
    settingsReducer,
    { setTempScale, setLanguage, displayOffline, refreshSettings },
    { tempScale: "F", hideOffline: true, language: 'en' }
);