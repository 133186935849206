import React, { useContext, useEffect } from 'react'

import { BrowserRouter as Router, Route, Switch, Redirect, matchPath } from "react-router-dom"

import { Context as AuthContext } from './context/AuthContext'

import Main from './Main'
import LoadingSpinner from './components/LoadingSpinner'

import { paths } from './utils/const'

const match = matchPath("/", {
  path: "/:path",
  exact: false,
  // strict: false
});

console.log("match: ", match)

const Auth = React.lazy(() => import("./screens/auth"));

const Routes = () => {

  const {
    state: authState,
    checkSignin,
    // signout 
  } = useContext(AuthContext);

  useEffect(() => {
    checkSignin();
    // console.log("Routes: checkSignin useEffect hook: ", authState)
  }, []);

  return (
    <Router>
      {(authState.signin == null) ?
        <LoadingSpinner />
        :
        <Switch>
          <Route path={paths.auth}>
            <Auth />
          </Route>
          <Route path={paths.root}>
            <Main />
          </Route>
          <Redirect to={paths.auth} />
        </Switch>
      }
    </Router>
  )
}

// <Switch>
// {/* <Route path={paths.dashboard}> */}
// {/* </Route> */}
// {/* <Redirect to={paths.dashboard} /> */}
// </Switch>
export default Routes