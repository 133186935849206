import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import senseiApi from '../../api/sensei';

import LoadingSpinner from '../../components/LoadingSpinner'

function getQueryString(data = {}) {
  return Object.entries(data)
    .map(([key, value]: [key: any, value: any]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

const ClaimCode = (props: any) => {
  let { claimCode } = useParams<{ claimCode: any | undefined }>();
  const [responseMessage, setResponseMessage] = useState("")

  const { t } = useTranslation();

  useEffect(() => {
    const checkClaimCode = async (code: string, resMessage: string) => {
      const response = await senseiApi.post('/claim-code', getQueryString({ claim_code: code }), { withCredentials: true })
      if (response.data.message === 'success') {
        setResponseMessage("claimcode-success");
        // console.log("claim code success: ", claimCode)
        // console.log("claim code success: ", claimCode)
        // history.push('/dashboard')
      }
      if (response.data.message === 'error') {
        setResponseMessage("claimcode-error");
        // console.log("claim code fail/error: ", claimCode)
        // console.log("claim code queryString: ", claimCode)
        // history.push('/dashboard')
      }

    }
    if (claimCode && responseMessage === "") {
      checkClaimCode(claimCode, responseMessage);
    }
  }, [claimCode, responseMessage])

  return (
    <>
      {(responseMessage === "") ?
        <LoadingSpinner />
        :
        <>
          <p>{t(responseMessage)}</p>
        </>
      }
    </>
  )
}

export default ClaimCode